import React from 'react'

// Styles
import style from './AutoPageSectionHeader.module.scss'

type AutoPageSectionHeaderProps = {
  labelText?: string
  titleText: string
  subtitleText?: string | JSX.Element
}

const AutoPageSectionHeader: React.FC<AutoPageSectionHeaderProps> = ({
  labelText,
  titleText,
  subtitleText,
}) => {
  return (
    <div className={style.header}>
      <span className="section-label-sm blue">{labelText}</span>
      <h2 className={style.title}>{titleText}</h2>
      {subtitleText && (
        <h5 className={`section-subtitle-sm lessBold`}>{subtitleText}</h5>
      )}
    </div>
  )
}

export default AutoPageSectionHeader
