import { useState, useEffect, useMemo } from 'react'

import useQuerySearchBuilder from 'hooks/useQuerySearchBuilder'

import type { QueryParams } from 'utils/search/QuerySearchBuilder/QuerySearchBuilder.types'
import type { PreservedQuery } from 'utils/Strings'

import { SEARCH_PAGE_PATH } from 'constants/paths'

const faqLinksQueryParams: Array<QueryParams> = [
  { qualities: { accessibility: ['Pets Allowed'] } },
  { qualities: { amenities: ['Pool'] } },
  { qualities: { amenities: ['Private Hot Tub'] } },
  { qualities: { accessibility: ['Suitable for Children'] } },
  {
    qualities: {
      views: 'All',
    },
  },
  { qualities: { amenities: ['Parking Available'] } },
  { qualities: { amenities: ['Wifi/Internet'] } },
]

const useFAQLinks = (
  cityAddress = '',
  preservedQuery?: PreservedQuery,
): Array<string> => {
  const [faqLinks, setFAQLinks] = useState<Array<string>>([])
  const queryParams = useMemo(
    () => ({ query: cityAddress, ...(preservedQuery ?? {}) }),
    [cityAddress, preservedQuery],
  )
  const queryBuilder = useQuerySearchBuilder(queryParams)

  useEffect(() => {
    const searchByCityUrl = `${SEARCH_PAGE_PATH}?${queryBuilder
      .build()
      .toString()}`

    const otherFAQLinks = faqLinksQueryParams.map(
      (queryFilters) =>
        `${SEARCH_PAGE_PATH}?${queryBuilder
          .build(queryFilters as any)
          .toString()}`,
    )

    setFAQLinks([searchByCityUrl, ...otherFAQLinks])
  }, [queryBuilder])

  return faqLinks
}

export default useFAQLinks
