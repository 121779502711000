import React, { useEffect } from 'react'
import { useSession } from 'next-auth/react'

import { useSelect } from 'store/index'

import useAugmentedRouter from 'hooks/useAugmentedRouter'
import { useLLPAnalyticsPage } from 'hooks/useLLPAnalyticsPage'

import AutoPageFaq from 'components/LocationLanding/AutoPage/Faq/AutoPageFaq'
import BreadcrumbUI from 'components/LocationLanding/AutoPage/Breadcrumb/Breadcrumb'
import EvolveDifference from 'components/EvolveDifference/EvolveDifference'
import AutoPageMetaTags from 'components/LocationLanding/AutoPage/MetaTags/AutoPageMetaTags'
import NearbyDestinations from 'components/LocationLanding/AutoPage/NearbyDestinations/NearbyDestinations'
import TopFacets from 'components/LocationLanding/AutoPage/TopFacets/TopFacets'
import Gtm from 'components/scripts/Gtm'
// import TopRated from 'components/LocationLanding/AutoPage/TopRated/TopRated'
import FavoritesLoginModal from 'components/Favorites/FavoritesLogin/FavoritesLoginModal'
import ResponsivePageLayout from 'components/Layout/ResponsivePageLayout'

import AutoPageHero from './AutoPage/Hero/AutoPageHero'

import {
  AutoLocationPagePlaceStructuredData,
  DateModifiedStructuredData,
  WebpageStructuredData,
} from 'utils/StructuredData'
import { pushToDataLayer } from 'utils/Gtm'

import { publicOrigin } from 'constants/globalEnv'

import type {
  AutoLocationLandingData,
  CountObject,
  ReviewMap,
  TopFacet,
  RegionsData,
  CloudinaryImageData,
} from 'types/locationLanding'
import type {
  AlgoliaListing,
  InitialSearch,
  BreadcrumbListItem,
} from 'types/externalData'

export type AutoLocationLandingPageProps = {
  breadcrumbData: BreadcrumbListItem[]
  footerData: any
  pageData: AutoLocationLandingData & {
    nearbyDestinations: AutoLocationLandingData[]
    slugArr: string[]
    faqAmenitiesCounts: CountObject
    topFacets: TopFacet[]
    topRatedListings: AlgoliaListing[]
    metaTitle: string
    metaDescription: string
    heading: string
    regionsData: RegionsData[]
    initialSearch: InitialSearch
    listingsCount: number
    topReviewsMap: ReviewMap
    hasReviews: boolean
    propertyTypeImages: { [key: string]: CloudinaryImageData }
    amenityImages: { [key: string]: CloudinaryImageData }
  }
  evolveDifference: any
}

const AutoLocationLandingPage: React.FC<AutoLocationLandingPageProps> = ({
  breadcrumbData,
  footerData,
  pageData,
  evolveDifference,
}) => {
  const {
    cityName,
    slugArr,
    nearbyDestinations,
    heroImage,
    dateModified,
    listingsCount,
    stateData,
    propertyTypeImages,
    amenityImages,
    faqAmenitiesCounts,
    topFacets,
    // topRatedListings,
    metaTitle,
    metaDescription,
    heading,
    // regionsData,
    initialSearch,
    // topReviewsMap,
  } = pageData

  const router = useAugmentedRouter()

  // Redux selectors
  const loginPromptModalOpen = useSelect(
    (state) => state.uiState.loginPromptModalOpen,
  )
  const saveAsGuest = useSelect((state) => state.favorites.saveAsGuest)

  const { data: session } = useSession()

  const headerData = {
    page: 'Location Landing Page',
    hideSearchBox: true,
  }

  useEffect(
    () =>
      window.dataLayer &&
      pushToDataLayer('LLPLocation', { LLPLocation: cityName }),
    [cityName],
  )

  const onBreadcrumbItemClicked = async (
    e: React.MouseEvent<HTMLAnchorElement>,
    bItem: BreadcrumbListItem,
    dataPath: string,
  ) => {
    e.preventDefault()

    const vacationRentalHome = `${publicOrigin}/vacation-rentals`

    if (bItem.item === vacationRentalHome) {
      router.push(vacationRentalHome)
      return
    }

    router.push({
      pathname: '/vacation-rentals/search',
      query: {
        ...router.query,
        query: dataPath,
        prevListingPage: router.asPath.split('?')[0],
      },
    })
  }

  useLLPAnalyticsPage()

  return (
    // TODO LLP: Double-check props
    <ResponsivePageLayout footerData={footerData} headerData={headerData}>
      <AutoPageMetaTags
        cityName={cityName}
        heroImage={heroImage.url}
        listingsCount={listingsCount}
        metaDescription={metaDescription}
        metaTitle={metaTitle}
        slugArr={slugArr}
        stateData={stateData}
        topFacets={topFacets}
      />
      <AutoLocationPagePlaceStructuredData
        cityName={cityName}
        slugArr={slugArr}
        stateData={stateData}
      />
      <DateModifiedStructuredData date={new Date(dateModified)} />
      <WebpageStructuredData
        canonicalUrl=""
        description={''}
        name={metaTitle}
        yoastData={{}}
      />
      <Gtm pageType="AutoLocationLanding" />
      <AutoPageHero
        cityName={cityName}
        heading={heading}
        heroImage={heroImage}
        initialSearch={initialSearch}
      />
      <TopFacets
        amenityImages={amenityImages}
        cityName={cityName}
        initialSearch={initialSearch}
        propertyTypeImages={propertyTypeImages}
        topFacets={topFacets}
      />
      {/* <TopRated
        cityName={cityName}
        topRatedListings={topRatedListings}
        topReviewsMap={topReviewsMap}
      /> */}
      <AutoPageFaq
        cityAddress={initialSearch?.address?.address}
        cityName={cityName}
        faqAmenitiesCounts={faqAmenitiesCounts}
        listingsCount={listingsCount}
      />
      <EvolveDifference
        boldSubtitle={true}
        evolveDifference={evolveDifference}
        isLLP
        small={false}
        styleClass="responsiveLayout"
      />
      <NearbyDestinations nearbyDestinations={nearbyDestinations} />
      <BreadcrumbUI
        breadcrumbData={breadcrumbData}
        onBreadcrumbItemClicked={onBreadcrumbItemClicked}
      />
      {loginPromptModalOpen && !saveAsGuest && !session && (
        <FavoritesLoginModal />
      )}
    </ResponsivePageLayout>
  )
}

export default AutoLocationLandingPage
