import { useContext, useEffect } from 'react'

import { PageHistoryContext } from 'context/PageHistoryContext'

export function useLLPAnalyticsPage() {
  const { pageHistory } = useContext(PageHistoryContext)

  useEffect(() => {
    const isPageLastestInHistory =
      pageHistory[pageHistory.length - 1]?.split('?')[0] !==
      window.location.href.split('?')[0]
    if (isPageLastestInHistory) return

    if (pageHistory.length === 1) {
      window.analytics.page('Location Landing Page')
      return
    }

    window.analytics.page('Location Landing Page', {
      referrer: pageHistory[pageHistory.length - 2],
    })
  }, [pageHistory])
}
