import React from 'react'
import Head from 'next/head'

// Types
import type { MetaTag, YoastMetadata } from 'types/externalData'

export const YoastTags = ({ tags }: { tags: MetaTag[] }) => {
  return (
    tags &&
    tags.map(
      (tag, i) =>
        tag.tag === 'meta' && (
          <Head key={i}>
            {tag.attributes.name && (
              <meta
                content={tag.attributes.content}
                name={tag.attributes.name}
              />
            )}
            {tag.attributes.property && (
              <meta
                content={tag.attributes.content}
                property={tag.attributes.property}
              />
            )}
          </Head>
        ),
    )
  )
}

export const buildYoastTags = (tags: MetaTag[]) => {
  return tags
    .filter((tag) => tag.tag === 'meta')
    .map((tag, i) => (
      <Head key={i}>
        {tag.attributes.name && (
          <meta content={tag.attributes.content} name={tag.attributes.name} />
        )}
        {tag.attributes.property && (
          <meta
            content={tag.attributes.content}
            property={tag.attributes.property}
          />
        )}
      </Head>
    ))
}

export const parseYoastData = (
  yoastMeta: YoastMetadata,
  headTags: MetaTag[],
) => {
  const title = yoastMeta.yoast_wpseo_title
  const description = yoastMeta.yoast_wpseo_metadesc
  const tags = headTags.reduce((acc: any, curr: any) => {
    if (curr.attributes.property && curr.attributes.property === 'og:type')
      acc = { ...acc, og_type: curr.attributes.content }
    if (curr.attributes.property && curr.attributes.property === 'og:title')
      acc = { ...acc, og_title: curr.attributes.content }
    if (
      curr.attributes.property &&
      curr.attributes.property === 'og:description'
    )
      acc = { ...acc, og_description: curr.attributes.content }
    if (curr.attributes.property && curr.attributes.property === 'og:image')
      acc = { ...acc, og_image: curr.attributes.content }
    if (curr.attributes.name && curr.attributes.name === 'twitter:title')
      acc = { ...acc, twitter_title: curr.attributes.content }
    if (curr.attributes.name && curr.attributes.name === 'twitter:description')
      acc = { ...acc, twitter_description: curr.attributes.content }
    if (curr.attributes.name && curr.attributes.name === 'twitter:card')
      acc = { ...acc, twitter_card: curr.attributes.content }
    if (curr.attributes.name && curr.attributes.name === 'twitter:site')
      acc = { ...acc, twitter_site: curr.attributes.content }
    if (curr.attributes.name && curr.attributes.name === 'twitter:image')
      acc = { ...acc, twitter_image: curr.attributes.content }
    return acc
  }, {})

  return { title: title, description: description, ...tags }
}

export const formatMetaTitle = (title: string) =>
  title ? `THE BEST ${title} Vacation Rentals | Evolve` : ''

export const formatAutoLandingPageMetaTitle = (title: string) =>
  title ? `THE BEST ${title} Vacation Rentals - Book Now | Evolve` : ''

export const textToMetaDesc = (text: string) =>
  text ? text.replace(/<(.*?)>/g, '').slice(0, 200) : ''

// Get Manual Location Landing Page metadata
export const buildMetaData = (pageData: any) => {
  const customTags: any[] = []
  const filteredTags = [
    ...pageData.head_tags.filter((tag) => tag.attributes.name !== 'robots'),
  ]
  for (let i = filteredTags.length - 1; i >= 0; --i) {
    // Use custom tag logic
    if (
      filteredTags[i].attributes.property === 'og:image' ||
      filteredTags[i].attributes.name === 'twitter:title' ||
      filteredTags[i].attributes.name === 'twitter:description' ||
      filteredTags[i].attributes.name === 'twitter:image'
    ) {
      customTags.push(filteredTags[i])
    }
    // Wordpress sets og:title to value of yoast_wpseo_title if it's empty
    if (filteredTags[i].attributes.property === 'og:title') {
      if (
        filteredTags[i].attributes.content !==
        pageData.yoast_meta.yoast_wpseo_title
      ) {
        customTags.push(filteredTags[i])
      }
      filteredTags.splice(i, 1)
    }
    // Wordpress sets og:description to value of yoast_wpseo_metadesc if it's empty
    if (filteredTags[i].attributes.property === 'og:description') {
      if (
        filteredTags[i].attributes.content !==
        pageData.yoast_meta.yoast_wpseo_metadesc
      ) {
        customTags.push(filteredTags[i])
      }
      filteredTags.splice(i, 1)
    }
    // Hardcoded tag values
    if (
      filteredTags[i].attributes.name === 'twitter:site' ||
      filteredTags[i].attributes.property === 'og:site_name' ||
      filteredTags[i].attributes.property === 'og:url'
    ) {
      filteredTags.splice(i, 1)
    }
  }
  return {
    filteredTags: filteredTags,
    seoData: parseYoastData(pageData.yoast_meta, customTags),
  }
}
