export const ROOT_PAGE_PATH = '/'

export const VACATION_RENTALS_PATH = `${ROOT_PAGE_PATH}vacation-rentals`

export const SEARCH_PAGE_PATH = `${VACATION_RENTALS_PATH}/search`

export const SITEMAP_ROOT_PATH = '/sitemap'

export const SITEMAP_VACATION_RENTALS_PATH = `${SITEMAP_ROOT_PATH}/vacation-rentals`

export const SITEMAP_US_PATH = `${SITEMAP_VACATION_RENTALS_PATH}/us`
