import React from 'react'

// Style
import style from './VacationIdeas.module.scss'

const VacationIdeas = (props) => {
  const { icon, title, ideas } = props.data
  return (
    <section className={`${style.section}`} id="vacation-ideas">
      <div className={`${style.content} content`}>
        <div className={style.header}>
          {icon && (
            <img alt={icon.alt ? icon.alt : title.trim()} src={icon.url} />
          )}
          <h2 className={`section-title`}>{title}</h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: ideas }} />
      </div>
    </section>
  )
}

export default VacationIdeas
