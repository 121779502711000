import React, { useMemo } from 'react'
import Image from 'next/image'

import { useSelect } from 'store/index'

import CustomLink from 'components/Link/CustomLink'

import style from './NearbyDestinations.module.scss'

import { DesktopBreakpoint } from 'config/Breakpoints'

import getWordpressCloudinaryId from 'utils/strings/getWordpressCloudinaryId'
import cloudinaryLoader from 'utils/imageLoaders/cloudinaryLoader'

import type { AutoLocationLandingData } from 'types/locationLanding'

type DestinationProps = {
  destination: Pick<
    AutoLocationLandingData,
    'cityName' | 'objectID' | 'heroImage' | 'stateData'
  >
  i: number
  displayNum: number
}

const Destination: React.FC<DestinationProps> = ({
  destination,
  i,
  displayNum,
}) => {
  // Redux Selectors
  const width = useSelect((state) => state.uiState.width)

  const hasMarginBottom = useMemo(() => {
    if (displayNum % 2 === 0) {
      if (i < displayNum / 2) {
        return true
      }
      return false
    } else {
      if (i === displayNum - 1) {
        return false
      }
      return true
    }
  }, [displayNum, i])

  return (
    <div
      className={`${style.destination} ${
        hasMarginBottom ? style.marginBottom : ''
      }`}
    >
      <CustomLink
        className={style.link}
        href={`/vacation-rentals/${destination.objectID}`}
        prefetch={false}
      >
        <div className={`${style.image}`}>
          <Image
            alt={destination.cityName}
            fill
            loader={cloudinaryLoader}
            loading="lazy"
            priority={false}
            quality={width < DesktopBreakpoint ? 55 : 65}
            sizes="(min-width: 900px) 320px, (min-width: 600px) 250px, 150px"
            src={getWordpressCloudinaryId(destination.heroImage.url)!}
            style={{
              objectFit: 'cover',
            }}
            title={
              destination.heroImage.context?.caption ??
              destination.heroImage.context?.alt ??
              ''
            }
          />
        </div>

        <div className={'description'}>
          <h5 className={style.displayText}>{`${destination.cityName}${
            destination.stateData.iso ? `, ${destination.stateData.iso}` : ''
          }`}</h5>
        </div>
      </CustomLink>
    </div>
  )
}

export default Destination
