import React from 'react'
import Image from 'next/image'

import SearchBar from 'components/Search/SearchBar'

import style from './ManualPageHero.module.scss'

import getWordpressCloudinaryId from 'utils/strings/getWordpressCloudinaryId'
import cloudinaryLoader from 'utils/imageLoaders/cloudinaryLoader'

import DesktopMask from 'assets/images/wave-v3.svg'

type ManualPageHeroProps = {
  heroGroup: {
    section_label: string
    section_title: string
    section_subtitle: string
    hero_image: string
    default_search: any
  }
}

const ManualPageHero: React.FC<ManualPageHeroProps> = ({ heroGroup }) => {
  const {
    section_label,
    section_title,
    section_subtitle,
    hero_image,
    default_search,
  } = heroGroup

  return (
    <section>
      <div className={style.hero}>
        <Image
          alt=""
          fill
          loader={cloudinaryLoader}
          priority={true}
          sizes="100vw"
          src={getWordpressCloudinaryId(hero_image)!}
          style={{
            objectFit: 'cover',
            objectPosition: 'center top',
          }}
        />
        <div className={style.heroContentContainer}>
          <div className={style.heroContent}>
            <span className={'section-label'}>{section_label}</span>
            <h1 className={style.title}>{section_title}</h1>
            <span className={style.subtitle}>{section_subtitle}</span>
            <SearchBar initialSearch={default_search} />
          </div>
        </div>
        <div className={style.mask}>
          <DesktopMask className={style.desktopMask} />
        </div>
      </div>
    </section>
  )
}

export default ManualPageHero
