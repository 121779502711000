import React from 'react'

import CustomLink from 'components/Link/CustomLink'
import SectionLabelCentered from 'components/Section-Labels/Section-Label-Centered'
import DescriptiveCard from 'components/Cards/DescriptiveCard'

import style from './DescriptiveCards.module.scss'

const DescriptiveCards = (props) => {
  const { label, title, subtitle, cards, button } = props.cardData
  return (
    <section className={`${style.section}`} id="things-to-do">
      <div className={`${style.content} content`}>
        <SectionLabelCentered label={label} subtitle={subtitle} title={title} />
      </div>
      <div className={style.cardGrid}>
        {cards.map(({ title, description }, i) => (
          <DescriptiveCard description={description} key={i} title={title} />
        ))}
      </div>
      <div className={`${style.content} content`}>
        {
          //Hide button if text or link is not set
          button.link && button.text && (
            <CustomLink
              className={`${style.button} btn-primary`}
              href={button.link}
              target={button.new_tab ? '_blank' : '_self'}
            >
              {button.text}
            </CustomLink>
          )
        }
      </div>
    </section>
  )
}

export default DescriptiveCards
