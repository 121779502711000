import React, { useMemo } from 'react'
import Image from 'next/image'

import { useSelect } from 'store/index'

import SearchBar from 'components/Search/SearchBar'

import style from './AutoPageHero.module.scss'

import { DesktopBreakpoint } from 'config/Breakpoints'

import getWordpressCloudinaryId from 'utils/strings/getWordpressCloudinaryId'
import cloudinaryLoader from 'utils/imageLoaders/cloudinaryLoader'
import getCloudinaryPlaceholder from 'utils/strings/getCloudinaryPlaceholder'

import GreenWaves from 'assets/icons/green-waves.svg'

import type { CloudinaryImageData } from 'types/locationLanding'
import type { InitialSearch } from 'types/externalData'

type AutoPageHeroProps = {
  heroImage: CloudinaryImageData
  cityName: string
  initialSearch: InitialSearch
  heading: string
}
const AutoPageHero: React.FC<AutoPageHeroProps> = ({
  heroImage,
  cityName,
  initialSearch,
  heading,
}) => {
  const title = heading || `Find ${cityName} Vacation Rentals`
  // Redux Selectors
  const width = useSelect((state) => state.uiState.width)

  const imgUrl = useMemo(
    () => getWordpressCloudinaryId(heroImage.url) ?? '',
    [heroImage.url],
  )

  // @TODO DD-646: Preload hero image (See components/Home/Hero/HomeHero.tsx)
  return (
    <section className={style.heroSection} id="AutoPageHero">
      <div className={style.hero}>
        <Image
          alt={heroImage.context?.alt ?? ''}
          blurDataURL={getCloudinaryPlaceholder(imgUrl)}
          fill
          loader={cloudinaryLoader}
          placeholder="blur"
          priority={true}
          quality={width < DesktopBreakpoint ? 45 : 65}
          sizes="(min-width: 900px) 1200px, (min-width: 600px) 600px, 400px"
          src={imgUrl}
          style={{
            objectFit: 'cover',
            objectPosition: 'center center',
          }}
          title={heroImage.context?.caption ?? heroImage.context?.alt ?? ''}
        />
        <div className={`${style.heroContentContainer}`}>
          {width >= DesktopBreakpoint && (
            // Desktop/Tablet layout
            <div className={style.heroContent}>
              <div className={style.labelRow}>
                <span className={'section-label'}>{`BECOME VACATION YOU`}</span>
                <GreenWaves height={27} width={47} />
              </div>
              <h1 className={style.title}>{title}</h1>
              <span
                className={style.subtitle}
              >{`Search houses, condos, apartments, and more. Enter your trip dates to get started.`}</span>
              <SearchBar
                initialSearch={initialSearch}
                isAutoPage={true}
                searchBarClass={style.autoPageSearchBar}
              />
            </div>
          )}
        </div>
      </div>

      {width < DesktopBreakpoint && (
        // Mobile layout
        <div className={style.heroContent}>
          <div className={style.labelRow}>
            <span className={'section-label'}>{`BECOME VACATION YOU`}</span>
            <GreenWaves height={27} width={47} />
          </div>
          <h1 className={style.title}>{`Find ${cityName} Vacation Rentals`}</h1>
          <span
            className={style.subtitle}
          >{`Search houses, condos, apartments, and more. Enter your trip dates to get started.`}</span>
          <SearchBar
            initialSearch={initialSearch}
            isAutoPage={true}
            searchBarClass={style.autoPageSearchBar}
          />
        </div>
      )}
    </section>
  )
}

export default AutoPageHero
