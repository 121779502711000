export const accessibilityTypes = [
  'Pets Allowed',
  'No Pets Allowed',
  'Suitable for Children',
  'Accessible Parking Spot',
] as const

export const viewTypes = [
  'Mountain View',
  'Water View',
  'Ocean View',
  'City / Skyline View',
  'Golf Course View',
  'Garden View',
  'Beach View',
  'Lake View',
] as const

export const amenityTypes = [
  'Pool (community)',
  'Pool',
  'Kitchen',
  'Private Hot Tub',
  'Wifi/Internet',
  'Parking Available',
  'Hot Tub (community)',
] as const

export const allQualityTypesByKey = {
  accessibility: accessibilityTypes,
  views: viewTypes,
  amenities: amenityTypes,
}
