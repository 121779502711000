import React from 'react'

import { useSelect } from 'store/index'

import BarLoader from './BarLoader'
import style from './ListingLoader.module.scss'

import { LargeTabletBreakpoint } from 'config/Breakpoints'

const Detail = () => (
  <div className={style.detail}>
    <div className={style.detailTop}>
      <BarLoader height={32} width={93} />
      <div className={style.detailTopRight}>
        <BarLoader height={32} numberOfBars={2} width={93} />
      </div>
    </div>
    <BarLoader height={48} marginBottom={10} width="100%" />
    <BarLoader height={48} marginBottom={16} width="100%" />
    <div className={style.detailBottom}>
      <div className={style.detailBottomLeft}>
        <BarLoader height={24} marginBottom={44} width="50%" />
        <BarLoader height={24} width="65%" />
      </div>
      <BarLoader height={98} width={98} />
    </div>
  </div>
)

const ListingLoader = () => {
  const width = useSelect((state) => state.uiState.width)

  const renderDetail = () => {
    const details = []

    for (let i = 0; i < 3; i++) {
      details.push(<Detail key={i} />)
    }

    return details
  }

  return (
    <>
      <div className={style.imageGallery}>
        <BarLoader
          height="100%"
          mobileWidth="100%"
          tabletWidth="49.5%"
          width="44.5%"
        />
        {width > 600 && (
          <BarLoader height="100%" tabletWidth="49.5%" width="44.5%" />
        )}
        {width > LargeTabletBreakpoint && (
          <BarLoader height="100%" width="10%" />
        )}
      </div>
      <div className={style.resultDetails}>
        <div className={style.details}>{renderDetail()}</div>
        <div className={style.booking}>
          <div className={style.bookingTop}>
            <BarLoader height={32} marginBottom={8} width={184} />
            <BarLoader height={32} marginBottom={21} width={184} />
            <BarLoader height={50} marginBottom={8} width="100%" />
            <BarLoader height={50} width="100%" />
          </div>
          <div className={style.bookingBottom}>
            <BarLoader height={50} width="100%" />
          </div>
        </div>
      </div>
    </>
  )
}

export default ListingLoader
