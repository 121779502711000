import React from 'react'

import { BreadcrumbListItem } from 'types/externalData'

export type BreadcrumbItemProps = {
  item: BreadcrumbListItem
  onBreadcrumbItemClicked?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  isLast?: boolean
}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  item,
  onBreadcrumbItemClicked,
  isLast = false,
}) => {
  return (
    <li>
      {isLast ? (
        <p>{item.name}</p>
      ) : (
        <a href={item.item} onClick={onBreadcrumbItemClicked}>
          {item.name}
        </a>
      )}
    </li>
  )
}

export default BreadcrumbItem
