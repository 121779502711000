import React, { useMemo } from 'react'

import style from './DescriptiveCard.module.scss'

import renderSpecialCharacters from 'utils/strings/renderSpecialCharacters'

type DescriptiveCardProps = {
  title: string
  description: string
}

const DescriptiveCard: React.FC<DescriptiveCardProps> = ({
  title,
  description,
}) => {
  const htmlDescription = /<p>(.+)<\/p>/g.exec(description)
  const decodedDescription = useMemo(
    () => htmlDescription && renderSpecialCharacters(htmlDescription[1]),
    [htmlDescription],
  )

  return (
    <div className={style.descriptiveCard}>
      <span className={style.descriptiveCardTitle}>{title}</span>
      <div className={style.descriptiveCardDescription}>
        <p>{decodedDescription ? decodedDescription : description}</p>
      </div>
    </div>
  )
}

export default DescriptiveCard
