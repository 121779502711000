// TODO LLP: This doesn't work well for pages without defined cities (/cabins-near-me)
import React from 'react'
import Head from 'next/head'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import { formatMetaTitle } from 'utils/Seo'

import type { YoastMetadata } from 'types/externalData'

type ManualPageMetaTagsProps = {
  pageTitle: string
  pageData: any | null
  yoastData: YoastMetadata
  seoData: { [key: string]: string } | null
  overviewImage: string
}

const ManualPageMetaTags: React.FC<ManualPageMetaTagsProps> = ({
  pageTitle,
  pageData,
  yoastData,
  seoData,
  overviewImage,
}) => {
  const { hero_group, recommended_properties } = pageData?.acf ?? {}
  const router = useAugmentedRouter()

  const buildTitle = (hero_group: any) => {
    if (hero_group) {
      const locationName =
        hero_group.default_search.address.city ??
        hero_group.default_search.address.country ??
        hero_group.default_search.address.state

      return locationName
        ? `THE BEST ${locationName} Vacation Rentals | Evolve`
        : hero_group.section_title
    }
  }

  const getTitle = (pageTitle: string, yoastData: YoastMetadata) => {
    if (
      yoastData &&
      yoastData.yoast_wpseo_title &&
      yoastData.yoast_wpseo_title !== `${pageTitle} | Evolve Vacation Rental`
    ) {
      return yoastData.yoast_wpseo_title
    }
    return formatMetaTitle(pageTitle)
  }

  const buildDescription = (hero_group: any, recommended_properties: any) => {
    let decription = ''

    if (hero_group) {
      decription = `${
        hero_group.default_search.address.city ??
        hero_group.default_search.address.state
      } Vacation Rentals – Find the perfect vacation home rental in ${
        hero_group.default_search.address.city ??
        hero_group.default_search.address.state
      }, ${
        hero_group.default_search.address.city
          ? hero_group.default_search.address.state_short
          : hero_group.default_search.address.country_short
      }. See high-quality photos • Verified Listings`
    }

    if (
      decription &&
      !recommended_properties.hide_this_section &&
      recommended_properties.featured_properties
    ) {
      decription += recommended_properties.featured_properties
        .slice(1, 3)
        .map((property: any) => ` • ${property.description}`)
        .join('')
    }

    return decription
  }

  return (
    <Head>
      <title>{getTitle(pageTitle, yoastData)}</title>
      {pageData?.acf?.custom_url_slug && (
        <link
          href={`${process.env.NEXT_PUBLIC_ORIGIN}/${pageData.acf.custom_url_slug}`}
          rel="canonical"
        />
      )}
      <meta
        content={
          seoData?.description
            ? seoData.description
            : pageData
            ? buildDescription(hero_group, recommended_properties)
            : ''
        }
        name="description"
      />
      <meta
        content={process.env.NEXT_PUBLIC_ORIGIN + router.asPath.split('?')[0]}
        property="og:url"
      />
      <meta content="Evolve Vacation Rental" property="og:site_name" />
      <meta content="@EvolveVR" name="twitter:site" />
      <meta
        content={
          seoData?.og_title
            ? seoData.og_title
            : pageData
            ? buildTitle(hero_group)
            : ''
        }
        property="og:title"
      />
      <meta
        content={
          seoData?.og_description
            ? seoData.og_description
            : pageData
            ? buildDescription(hero_group, recommended_properties)
            : ''
        }
        property="og:description"
      />
      <meta
        content={seoData?.og_image ? seoData.og_image : overviewImage}
        property="og:image"
      />
      <meta
        content={
          seoData?.twitter_title
            ? seoData.twitter_title
            : pageData
            ? buildTitle(hero_group)
            : ''
        }
        name="twitter:title"
      />
      <meta
        content={
          seoData?.twitter_description
            ? seoData.twitter_description
            : pageData
            ? buildDescription(hero_group, recommended_properties)
            : ''
        }
        name="twitter:description"
      />
      <meta
        content={seoData?.twitter_image ? seoData.twitter_image : overviewImage}
        name="twitter:image"
      />
    </Head>
  )
}

export default ManualPageMetaTags
