import React, { useState } from 'react'

import SectionLabelCentered from 'components/Section-Labels/Section-Label-Centered'

import style from './ManualPageFaq.module.scss'

import { FaqStructuredData } from 'utils/StructuredData'

import PlusIcon from 'assets/icons/plus.svg'

const ManualPageFaq = (props) => {
  const { title, subtitle, faqs } = props.faqData

  const [active, setActive] = useState(null)

  const openQuestion = (num) => {
    if (active === num) {
      setActive(null)
    } else {
      setActive(num)
    }
  }

  return (
    <section className={`${style.section}`} id="faq">
      <div className={`${style.content} content`}>
        <SectionLabelCentered subtitle={subtitle} title={title} />
      </div>
      <div className={`${style.faqList}`}>
        {faqs.map((faq, i) => (
          <button className={`${style.faq} FAQ-btn`} key={i}>
            <div
              className={
                active === i
                  ? style.questionWrapperActive
                  : style.questionWrapper
              }
              onClick={() => openQuestion(i)}
            >
              <PlusIcon />
              <span>{faq.question}</span>
            </div>
            <div
              className={
                active === i ? style.answerWrapperActive : style.answerWrapper
              }
            >
              <span dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </div>
          </button>
        ))}
      </div>
      {faqs.length && <FaqStructuredData faqData={faqs} />}
    </section>
  )
}

export default ManualPageFaq
