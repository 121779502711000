import React, { useCallback, useMemo } from 'react'

import { useSelect } from 'store/index'

import CustomLink from 'components/Link/CustomLink'

import style from './TopFacets.module.scss'
import Facet from './Facet'

import AutoPageSectionHeader from '../SectionHeader/AutoPageSectionHeader'

import { TabletBreakpoint } from 'config/Breakpoints'

import { searchStateToUrl } from 'utils/Search'
import { getLocationFacetDisplayText } from 'utils/locationLanding/topFacets'

import type { FacetImageData, TopFacet } from 'types/locationLanding'
import type { InitialSearch } from 'types/externalData'

type TopFacetsProps = {
  cityName: string
  topFacets: TopFacet[]
  propertyTypeImages: FacetImageData
  amenityImages: FacetImageData
  initialSearch: InitialSearch
}

const TopFacets: React.FC<TopFacetsProps> = ({
  cityName,
  topFacets,
  propertyTypeImages,
  amenityImages,
  initialSearch,
}) => {
  // Redux Selectors
  const width = useSelect((state) => state.uiState.width)

  const getSearchUrl = useCallback(
    (refinementListObj?) => {
      const searchState = {
        query: initialSearch.address.address ?? '',
        refinementList: refinementListObj ?? {},
      }
      return searchStateToUrl(searchState)
    },
    [initialSearch.address],
  )

  const topFacetData = useMemo(
    () =>
      topFacets.map((facet) => {
        switch (facet.category) {
          case 'listings':
            return {
              searchUrl: getSearchUrl(),
              count: facet.count,
              image: propertyTypeImages['All Properties'],
              displayText: `All ${cityName} Vacation Rentals`,
            }
          case 'propertyType':
            return {
              searchUrl: getSearchUrl({
                ['Property Type']: [facet.name],
              }),
              count: facet.count,
              image: propertyTypeImages[facet.name],
              displayText: `${facet.name} Vacation Rentals`,
            }
          case 'pets':
            return {
              searchUrl: getSearchUrl({
                ['amenities.Accessibility']: [facet.name],
              }),
              count: facet.count,
              image: amenityImages['Pets Allowed'],
              displayText: `Pet-Friendly Vacation Rentals`,
            }
          case 'mostPopular':
            return {
              searchUrl: getSearchUrl({
                ['amenities.Amenities']: [facet.name],
              }),
              count: facet.count,
              image: amenityImages[facet.name],
              displayText: `Vacation Rentals with ${
                facet.name.match(/pool/gi) ? 'Pools' : facet.name
              }`,
            }
          case 'Location':
            return {
              searchUrl: getSearchUrl({
                ['amenities.Location']: [facet.name],
              }),
              count: facet.count,
              image: amenityImages[facet.name],
              displayText: getLocationFacetDisplayText(facet.name),
            }
          case 'parking':
            return {
              searchUrl: getSearchUrl({
                ['amenities.Amenities']: [facet.name],
              }),
              count: facet.count,
              image: amenityImages[facet.name],
              displayText: `Vacation Rentals with Parking`,
            }
          case 'Accessibility': // Suitable for Children
            return {
              searchUrl: getSearchUrl({
                ['amenities.Accessibility']: [facet.name],
              }),
              count: facet.count,
              image: amenityImages[facet.name],
              displayText: `Vacation Rentals ${facet.name}`,
            }
          case 'View':
            return {
              searchUrl: getSearchUrl({
                ['amenities.View']: [facet.name],
              }),
              count: facet.count,
              image: amenityImages[facet.name],
              displayText: `Vacation Rentals with ${facet.name}`,
            }

          default:
            return {
              searchUrl: getSearchUrl(),
              count: 0,
              image: {
                url: '',
                context: {
                  alt: '',
                  caption: '',
                },
              },
              displayText: '',
            }
        }
      }),
    [amenityImages, cityName, getSearchUrl, propertyTypeImages, topFacets],
  )

  const displayNum = useMemo(
    () => (width < TabletBreakpoint ? 4 : topFacetData.length),
    [topFacetData.length, width],
  )

  const subtitleText = (
    <>
      {`No matter what space you need, you can`}
      {` `}
      <CustomLink
        className={``}
        href={`/rest-easy-promise`}
        prefetch={false}
        target="_blank"
        title={`rest easy`}
      ></CustomLink>
      {` `}
      {`in our ${cityName} vacation rentals.`}
    </>
  )

  return topFacetData.length ? (
    <section id="TopFacets">
      <div className={`${style.content} content`}>
        <AutoPageSectionHeader
          labelText={`BEST PLACES TO STAY`}
          subtitleText={subtitleText}
          titleText={`Book Your Perfect Place`}
        />
        <div className={style.facetsContainer}>
          {topFacetData.slice(0, displayNum).map((facetData) => (
            <Facet facetData={facetData} key={facetData.displayText} />
          ))}
        </div>
      </div>
    </section>
  ) : null
}

export default TopFacets
