import React from 'react'

import BreadcrumbItem from './BreadcrumbItem'
import styles from './Breadcrumb.module.scss'

import { BreadcrumbListItem } from 'types/externalData'

export type BreadcrumbProps = {
  items: BreadcrumbListItem[]
  onBreadcrumbItemClicked?: (
    e: React.MouseEvent<HTMLAnchorElement>,
    bItem: BreadcrumbListItem,
    dataPath: string,
  ) => void
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  items,
  onBreadcrumbItemClicked,
}) => {
  let dataPath = ''
  const dataPaths: string[] = items.map((item, itemIndex) => {
    if (itemIndex === 0) {
      return item.name
    } else {
      dataPath = dataPath.length === 0 ? item.name : `${item.name}--${dataPath}`
      return dataPath
    }
  })

  return (
    <ul className={styles.breadcrumb}>
      {items.map((item, index) => (
        <BreadcrumbItem
          isLast={index === items.length - 1}
          item={item}
          key={item.name}
          onBreadcrumbItemClicked={(e: React.MouseEvent<HTMLAnchorElement>) => {
            if (onBreadcrumbItemClicked) {
              onBreadcrumbItemClicked(e, item, dataPaths[index])
            }
          }}
        />
      ))}
    </ul>
  )
}

export default Breadcrumb
