import React from 'react'

// Styles
import style from './SeeMoreButton.module.scss'

export type SeeMoreButtonProps = {
  open: boolean
  setOpen: (value: any) => void
  id: string
  customText?: { isOpenText: string; isClosedText: string }
  cssClass?: string
}

const SeeMoreButton: React.FC<SeeMoreButtonProps> = ({
  open,
  setOpen,
  id,
  customText,
  cssClass,
}) => {
  return (
    <button
      className={`${open ? style.btnActive : style.btn} ${cssClass ?? ''}`}
      id={id}
      onClick={setOpen}
    >
      {open
        ? `${customText?.isOpenText ?? 'Show Less'}`
        : `${customText?.isClosedText ?? 'Show More'}`}
    </button>
  )
}

export default SeeMoreButton
