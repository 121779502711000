/*
 * This file is used for Listing pages, Manual Location Landing pages, and Auto Location Landing pages.
 * A dynamic route to cover all of them is required because their URL structures are far too similar.
 * Listing pages have a listing ID as the the last path segment - vacation-rentals/us/va/hiwassee/312403.
 * Both Location Landing page types follow the `vacation-rentals/<country-short>/<state-short>/<city>` format - /vacation-rentals/us/tn/pigeon-forge.
 * Manual pages (from WordPress) can also have just 1 segemnt after vacation-rentals/ - vacation-rentals/a-frame-cabin-rentals-near-me.
 * Auto Landing pages may not always have a `/<state-short>/` segment - vacation-rentals/mx/puerto-vallarta.
 */
import React from 'react'
import algoliasearch from 'algoliasearch'
import dynamic from 'next/dynamic'
import type {
  NextPage,
  GetStaticPaths,
  GetStaticProps,
  InferGetStaticPropsType,
} from 'next'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import ErrorPage from 'pages/404'

import AutoLocationLandingPage from 'components/LocationLanding/AutoLocationLandingPage'
import ManualLocationLandingPage from 'components/LocationLanding/ManualLocationLandingPage'
import ListingLoader from 'components/Loader/ListingLoader'
import type { ListingPageProps } from 'components/Result-Details/ListingPage'
import type { AutoLocationLandingPageProps } from 'components/LocationLanding/AutoLocationLandingPage'
import type { ManualLocationLandingPageProps } from 'components/LocationLanding/ManualLocationLandingPage'

import { urlPrettySpaces } from 'utils/Strings'
import {
  fetchFooterData,
  fetchGlobalOptionsData,
  fetchListingPageData,
  fetchListingReviews,
  fetchLocationLandingPagePaths,
  fetchWordpressPageData,
  ListingResignedError,
} from 'utils/staticData'
import renderSpecialCharacters from 'utils/strings/renderSpecialCharacters'
import {
  textToMetaDesc,
  buildMetaData,
  formatAutoLandingPageMetaTitle,
} from 'utils/Seo'
import {
  getListingData,
  getInitialSearch,
  arrToObj,
} from 'utils/locationLanding/pageData'
import { getRegionsData } from 'utils/regionalLinks'
import { BreadcrumbsStructuredData } from 'utils/StructuredData'
import { getFullFormLocationFromListing } from 'utils/Listings'

import { UNITED_STATES_ABBR } from 'constants/countryCodes'
import { publicOrigin } from 'constants/globalEnv'

import type { BreadcrumbListItem } from 'types/externalData'
import type {
  AutoLocationLandingData,
  SharedImageData,
} from 'types/locationLanding'

// Need to pass extra data to Auto Location Landing pages to work with other 2 page types
type AutoLocationPropsWithExtraData = AutoLocationLandingPageProps & any

const ListingPage = dynamic(
  () => import('components/Result-Details/ListingPage'),
)

/**
 *
 */
async function getLocationLandingIndex(
  slug?: string,
): Promise<AutoLocationLandingData | null> {
  if (!slug) {
    return null
  }

  const locationLandingClient = algoliasearch(
    `${process.env.NEXT_PUBLIC_ALGOLIA_APP_ID}`,
    `${process.env.NEXT_PUBLIC_ALGOLIA_LOCATION_LANDING_INDEX_API_KEY}`,
  )

  const locationLandingIndex = locationLandingClient.initIndex(
    process.env.NEXT_PUBLIC_ALGOLIA_LOCATION_LANDING_INDEX!,
  )

  return locationLandingIndex.getObject<AutoLocationLandingData>(slug)
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getListingPageBreadcrumbData = (
  homeAddress: string,
  listing: any,
): BreadcrumbListItem[] => {
  const breadcrumbData: BreadcrumbListItem[] = [
    {
      name: 'Vacation Rentals',
      item: homeAddress,
    },
  ]

  let previousAddress = homeAddress
  const fullformLocation = getFullFormLocationFromListing(listing)

  if (fullformLocation.country) {
    previousAddress = `${previousAddress}/${urlPrettySpaces(
      listing.Country,
    )}`.toLowerCase()

    breadcrumbData.push({
      name: fullformLocation.country,
      item: previousAddress,
    })
  }
  if (fullformLocation.country === 'USA' && fullformLocation.state) {
    previousAddress = `${previousAddress}/${urlPrettySpaces(
      listing.State,
    )}`.toLowerCase()

    breadcrumbData.push({
      name: fullformLocation.state,
      item: previousAddress,
    })
  }
  if (fullformLocation.city) {
    previousAddress = `${previousAddress}/${urlPrettySpaces(
      listing.City,
    )}`.toLowerCase()

    breadcrumbData.push({
      name: fullformLocation.city,
      item: previousAddress,
    })
  }
  if (listing.objectId) {
    previousAddress = `${previousAddress}/${listing.objectId}`.toLowerCase()

    breadcrumbData.push({
      name: `Listing ${listing.objectId}`,
      item: previousAddress,
    })
  }

  return breadcrumbData
}

export const getLandingPageBreadcrumbData = (
  homeAddress: string,
  pageData: AutoLocationLandingData,
): BreadcrumbListItem[] => {
  const breadcrumbData: BreadcrumbListItem[] = [
    {
      name: 'Vacation Rentals',
      item: homeAddress,
    },
  ]

  let previousAddress = homeAddress

  if (pageData.countryData) {
    previousAddress = `${previousAddress}/${urlPrettySpaces(
      pageData.countryData.iso,
    )}`.toLowerCase()

    breadcrumbData.push({
      name:
        pageData.countryData.iso === UNITED_STATES_ABBR
          ? 'USA'
          : pageData.countryData.iso,
      item: previousAddress,
    })
  }
  if (pageData.countryData.iso === UNITED_STATES_ABBR && pageData.stateData) {
    previousAddress = `${previousAddress}/${urlPrettySpaces(
      pageData.stateData.iso,
    )}`.toLowerCase()

    breadcrumbData.push({
      name: pageData.stateData.name,
      item: previousAddress,
    })
  }
  if (pageData.cityName) {
    previousAddress = `${previousAddress}/${urlPrettySpaces(
      pageData.cityName,
    )}`.toLowerCase()

    breadcrumbData.push({
      name: pageData.cityName,
      item: previousAddress,
    })
  }

  return breadcrumbData
}

function floorNearByResults(
  results: Array<AutoLocationLandingData>,
): Array<AutoLocationLandingData> {
  const ROW_RATE = 3
  const isLengthInRate = !(results.length % ROW_RATE)
  const roundedResults = isLengthInRate ? results : results.slice(0, ROW_RATE)
  return roundedResults.length >= 3 ? roundedResults : []
}

export const getStaticProps: GetStaticProps<
  ListingPageProps &
    AutoLocationPropsWithExtraData &
    ManualLocationLandingPageProps & {
      pageType: 'LISTING' | 'AUTO_LANDING' | 'MANUAL_LANDING'
    }
> = async (context) => {
  // Data shared between all page types
  const [globalOptionsData, footerData] = (
    await Promise.allSettled([fetchGlobalOptionsData(), fetchFooterData()])
  ).map((response) => (response.status === 'fulfilled' ? response.value : null))
  const pageFooterData = {
    menus: footerData,
    options: globalOptionsData.acf.global_footer_group,
  }
  const date = new Date().toString()

  const vacationRentalHome = `${publicOrigin}/vacation-rentals`

  // LISTING PAGE
  // If last path segment is a number, return listing page
  const listingId = context.params?.slug![
    context.params.slug!.length - 1
  ] as string
  if (!isNaN(parseInt(listingId))) {
    try {
      const listing = await fetchListingPageData(listingId, true)

      if (!listing) {
        return {
          notFound: true,
        }
      }

      const listingReviews = await fetchListingReviews(listingId)

      const breadcrumbData: BreadcrumbListItem[] = getListingPageBreadcrumbData(
        vacationRentalHome,
        listing,
      )

      return {
        props: {
          pageType: 'LISTING',
          breadcrumbData,
          pageData: null,
          pageTitle: '',
          pageId: null,
          footerData: pageFooterData,
          evolveDifference: globalOptionsData.acf.global_evolve_difference,
          metaDesc: '',
          seoData: null,
          filteredTags: null,
          listing: listing,
          listingReviews: listingReviews,
          lastModifiedDate: date,
        },
        revalidate: process.env.LISTING_PAGE_REVALIDATION_PERIOD
          ? parseInt(process.env.LISTING_PAGE_REVALIDATION_PERIOD)
          : undefined,
      }
    } catch (err) {
      /**
       * If the listing is resigned, we want to redirect (temporarily) to the
       * nearest location landing page. If there is no location landing page,
       * we will redirect to the vacation rental page.
       */
      if (err instanceof ListingResignedError) {
        // Check if the path is in the location landing page index.
        const locationIndex = await getLocationLandingIndex(
          err.getSearchIndex(),
        ).catch(() => {
          return null
        })

        return {
          redirect: {
            permanent: false,
            destination: locationIndex
              ? err.getNearestLocationPath()
              : err.getFallbackPath(),
          },
        }
      }

      /**
       * All other scenarios will return a 404 page.
       */
      return {
        notFound: true,
      }
    }
  } else {
    // Page can be either Location Landing page type
    const wpPagePaths = await fetchLocationLandingPagePaths(context.preview)

    const currentPath =
      context.params?.slug && typeof context.params.slug !== 'string'
        ? 'vacation-rentals/' + context.params.slug.join('/')
        : ''

    // MANUAL_LANDING PAGE
    // Check if current path exists as a wordpress page
    if (wpPagePaths.find((page) => page.path === currentPath)) {
      // Get page data from wordpress
      const page =
        currentPath && wpPagePaths.find((page) => page.path === currentPath)
      const pageData = page?.slug
        ? await fetchWordpressPageData(page.slug, context.preview)
        : null

      if (!pageData) {
        return {
          notFound: true,
        }
      }

      const { seoData, filteredTags } = buildMetaData(pageData)

      return {
        props: {
          pageType: 'MANUAL_LANDING',
          breadcrumbData: [],
          pageData: pageData,
          pageTitle: renderSpecialCharacters(pageData.title.rendered),
          footerData: pageFooterData,
          evolveDifference: globalOptionsData.acf.global_evolve_difference,
          metaDesc: textToMetaDesc(pageData.acf.overview.body),
          seoData: seoData,
          filteredTags: filteredTags,
          listing: null,
          listingReviews: null,
          lastModifiedDate: date,
        },
        revalidate: process.env.LOCATION_LANDING_PAGE_REVALIDATION_PERIOD
          ? parseInt(process.env.LOCATION_LANDING_PAGE_REVALIDATION_PERIOD)
          : undefined,
      }
    } else {
      // AUTO_LANDING PAGE
      // Location landing index config
      const locationLandingClient = algoliasearch(
        `${process.env.NEXT_PUBLIC_ALGOLIA_APP_ID}`,
        `${process.env.NEXT_PUBLIC_ALGOLIA_LOCATION_LANDING_INDEX_API_KEY}`,
      )
      const locationLandingIndex = locationLandingClient.initIndex(
        process.env.NEXT_PUBLIC_ALGOLIA_LOCATION_LANDING_INDEX!,
      )

      // Search index config
      const searchClient = algoliasearch(
        `${process.env.NEXT_PUBLIC_ALGOLIA_APP_ID}`,
        `${process.env.NEXT_PUBLIC_ALGOLIA_API_KEY}`,
      )
      const searchIndex = searchClient.initIndex(
        process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX!,
      )
      // Get location landing index data
      // getObject returns undefined if not found
      const pageData = context.params?.slug
        ? await locationLandingIndex
            .getObject<AutoLocationLandingData>(
              [...context.params?.slug]?.join('/'),
            )
            .catch((err) => console.error(err))
        : ({} as AutoLocationLandingData)

      // 404 if city isn't in location landing index, go to the search page
      if (!pageData) {
        if (context.params?.slug && Array.isArray(context.params?.slug)) {
          // Generate a query from slug, which can be used in search page
          const slugArray = [...context.params?.slug]
          if (slugArray[0] === UNITED_STATES_ABBR.toLowerCase()) {
            slugArray.splice(0, 1, 'USA')
          }
          const searchQuery =
            slugArray.length > 1
              ? slugArray.reverse().join('--').toUpperCase()
              : slugArray.toString().toUpperCase()
          if (searchQuery) {
            // Redirect to the search page
            return {
              redirect: {
                destination: `/vacation-rentals/search?query=${searchQuery}`,
                permanent: false,
              },
            }
          }
        }

        return {
          notFound: true,
        }
      }

      // Get city's listing data from search index
      const listingsRes = await searchIndex
        .search<any>('', {
          facetFilters: [
            `City:${pageData.cityName}`,
            `State:${
              pageData.countryData.iso === 'US' ? pageData.stateData.iso : ''
            }`,
            `Country:${
              // VI is treated as a country in listing index
              pageData.stateData.iso === 'VI'
                ? pageData.stateData.iso
                : pageData.countryData.iso
            }`,
          ],
          attributesToHighlight: [],
          attributesToRetrieve: [
            'Property Type',
            'Average Rating',
            'amenities',
            'Headline',
            'ImageUrl',
            '_geoloc',
            'City',
            'State',
            'Country',
            'Number of Reviews',
            'Average Per Night',
          ],
          hitsPerPage: 1000,
          page: 0,
          analytics: false,
        })
        .catch((err) => console.error(err))

      // TODO LLP: Handle this in UI
      if (!listingsRes?.hits || listingsRes?.hits.length === 0) {
        return {
          notFound: true,
        }
      }

      // Handle data transformations
      const listingData = await getListingData(listingsRes.hits)
      const metaTitle =
        pageData?.meta?.title ||
        formatAutoLandingPageMetaTitle(pageData.cityName)
      const metaDescription = pageData?.meta?.description || ''
      const heading = pageData?.heading || ''

      const initialSearch = getInitialSearch(
        pageData.cityData,
        pageData.stateData,
        pageData.countryData,
      )
      const listingsCount = listingsRes.hits.length

      // Get nearby cities data from location landing index
      let nearbyDestinations: Array<AutoLocationLandingData> = []
      try {
        const twoHundredMilles = 321869
        const minRequiredDestionations = 2
        const { hits: nearbyDestinationsRes } =
          await locationLandingIndex.search<AutoLocationLandingData>('', {
            aroundLatLng: `${pageData._geoloc.lat},${pageData._geoloc.lng}`,
            aroundRadius: twoHundredMilles,
            filters: `NOT objectID:'${pageData.objectID}'`,
            attributesToRetrieve: [
              'objectID',
              'cityName',
              'stateData',
              'heroImage',
            ],
            attributesToHighlight: [],
            hitsPerPage: 6,
            page: 0,
            analytics: false,
          })
        if (nearbyDestinationsRes.length <= minRequiredDestionations) {
          nearbyDestinations = []
        }
        nearbyDestinations = floorNearByResults(nearbyDestinationsRes)
      } catch (err) {
        console.error(err)
      }

      // Shared images index config
      const sharedImagesClient = algoliasearch(
        `${process.env.NEXT_PUBLIC_ALGOLIA_APP_ID}`,
        `${process.env.NEXT_PUBLIC_ALGOLIA_SHARED_IMAGES_INDEX_API_KEY}`,
      )
      const sharedImagesIndex = sharedImagesClient.initIndex(
        process.env.NEXT_PUBLIC_ALGOLIA_SHARED_IMAGES_INDEX!,
      )

      // Get shared image data for page's topFactes
      let propertyTypeImagesList: Array<SharedImageData> = []
      let amenityImagesList: Array<SharedImageData> = []

      try {
        const requiredImages = listingData.topFacets.map((facet) => facet.name)

        const { results: sharedImagesRes } =
          await sharedImagesIndex.getObjects<SharedImageData>(requiredImages)

        if (sharedImagesRes && sharedImagesRes.length) {
          sharedImagesRes.forEach((imageData) => {
            if (imageData) {
              switch (imageData.imageCategory) {
                case 'propertyTypeImages':
                  propertyTypeImagesList = [
                    ...propertyTypeImagesList,
                    imageData,
                  ]
                  break
                case 'amenityImages':
                  amenityImagesList = [...amenityImagesList, imageData]
                  break
                default:
                  break
              }
            }
          })
        }
      } catch (err) {
        console.error(err)
      }

      // Regional Links data
      const regionsData = await getRegionsData()
      const breadcrumbData: BreadcrumbListItem[] = getLandingPageBreadcrumbData(
        vacationRentalHome,
        pageData,
      )

      return {
        props: {
          pageType: 'AUTO_LANDING',
          breadcrumbData,
          pageData: {
            ...pageData,
            ...listingData,
            listingsCount: listingsCount,
            metaTitle: metaTitle,
            metaDescription,
            heading,
            regionsData: regionsData,
            initialSearch: initialSearch,
            nearbyDestinations,
            slugArr: context.params?.slug ?? [],
            propertyTypeImages: arrToObj(propertyTypeImagesList, 'imageName'),
            amenityImages: arrToObj(amenityImagesList, 'imageName'),
          },
          footerData: pageFooterData,
          evolveDifference: globalOptionsData.acf.global_evolve_difference,
          pageTitle: '',
          metaDesc: '',
          seoData: null,
          filteredTags: null,
          listing: null,
          listingReviews: null,
          lastModifiedDate: date,
        },
        revalidate: process.env.LOCATION_LANDING_PAGE_REVALIDATION_PERIOD
          ? parseInt(process.env.LOCATION_LANDING_PAGE_REVALIDATION_PERIOD)
          : undefined,
      }
    }
  }
}

// Component
const ListingAndLocationLandingTemplate: NextPage<
  InferGetStaticPropsType<typeof getStaticProps>
> = ({
  footerData,
  pageData,
  breadcrumbData,
  evolveDifference,
  lastModifiedDate,
  pageType,
  pageTitle,
  metaDesc,
  seoData,
  filteredTags,
  listing,
  listingReviews,
}) => {
  const router = useAugmentedRouter()

  // fallback should only matter for listing pages
  return (
    <>
      {router.isFallback ? (
        <ListingLoader />
      ) : (
        <>
          <BreadcrumbsStructuredData elements={breadcrumbData} />

          {pageType === 'LISTING' ? (
            <ListingPage
              breadcrumbData={breadcrumbData}
              evolveDifference={evolveDifference}
              footerData={footerData}
              lastModifiedDate={lastModifiedDate}
              listing={listing}
              listingReviews={listingReviews}
            />
          ) : pageType === 'AUTO_LANDING' ? (
            <AutoLocationLandingPage
              breadcrumbData={breadcrumbData}
              evolveDifference={evolveDifference}
              footerData={footerData}
              pageData={pageData}
            />
          ) : pageType === 'MANUAL_LANDING' ? (
            <ManualLocationLandingPage
              evolveDifference={evolveDifference}
              filteredTags={filteredTags}
              footerData={footerData}
              metaDesc={metaDesc}
              pageData={pageData}
              pageTitle={pageTitle}
              seoData={seoData}
            />
          ) : (
            <ErrorPage />
          )}
        </>
      )}
    </>
  )
}

export default ListingAndLocationLandingTemplate
