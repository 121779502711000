import React, { useMemo } from 'react'
import Head from 'next/head'

// Hooks
import useAugmentedRouter from 'hooks/useAugmentedRouter'

// Types
import type { DetailedLocationData, TopFacet } from 'types/locationLanding'

type AutoPageMetaTagsProps = {
  cityName: string
  slugArr: string[]
  topFacets: TopFacet[]
  heroImage: string
  listingsCount: number
  metaTitle: string
  metaDescription: string
  stateData: DetailedLocationData
}

const AutoPageMetaTags: React.FC<AutoPageMetaTagsProps> = ({
  cityName,
  slugArr,
  topFacets,
  heroImage,
  listingsCount,
  metaTitle,
  metaDescription,
  stateData,
}) => {
  const router = useAugmentedRouter()

  const upperCaseSlugArr = useMemo(
    () => slugArr.map((el) => el.toUpperCase()),
    [slugArr],
  )

  const metaUrl = useMemo(
    () => process.env.NEXT_PUBLIC_ORIGIN + router.asPath.split('?')[0],
    [router],
  )

  const metaDescriptionFormula = useMemo(() => {
    let decription = ''

    if (upperCaseSlugArr) {
      decription = `${listingsCount} ${
        cityName ?? stateData?.name ?? upperCaseSlugArr[1]
      } Vacation Rentals - Book the perfect vacation home rental in ${
        cityName ?? stateData?.name ?? upperCaseSlugArr[1]
      }, ${
        cityName ? upperCaseSlugArr[1] : upperCaseSlugArr[0]
      }. See verified listings • High-quality photos`
    }

    if (topFacets) {
      decription += ` • ${
        topFacets.find((facet) => facet.category === 'propertyType')?.name
      }s • ${topFacets.find((facet) => facet.category === 'mostPopular')?.name}`
    }

    return decription
  }, [cityName, listingsCount, topFacets, upperCaseSlugArr, stateData.name])

  return (
    <Head>
      <title>{metaTitle}</title>
      <link href={metaUrl} rel="canonical" />
      <meta
        content={metaDescription || metaDescriptionFormula}
        name="description"
      />
      <meta content={metaUrl} property="og:url" />
      <meta content="Evolve Vacation Rental" property="og:site_name" />
      <meta content="@EvolveVR" name="twitter:site" />
      <meta content={metaTitle} property="og:title" />
      <meta
        content={metaDescription || metaDescriptionFormula}
        property="og:description"
      />
      <meta content={heroImage} property="og:image" />
      <meta content={metaTitle} name="twitter:title" />
      <meta
        content={metaDescription || metaDescriptionFormula}
        name="twitter:description"
      />
      <meta content={heroImage} name="twitter:image" />
    </Head>
  )
}

export default AutoPageMetaTags
