import React, { useMemo } from 'react'

import { useSelect } from 'store/index'

import style from './NearbyDestinations.module.scss'
import Destination from './Destination'

import AutoPageSectionHeader from '../SectionHeader/AutoPageSectionHeader'

import { TabletBreakpoint } from 'config/Breakpoints'

import { AutoLocationLandingData } from 'types/locationLanding'

type NearbyDestinationsProps = {
  nearbyDestinations: AutoLocationLandingData[]
}

const NearbyDestinations: React.FC<NearbyDestinationsProps> = ({
  nearbyDestinations,
}) => {
  // Redux Selectors
  const width = useSelect((state) => state.uiState.width)

  const displayDestinations = useMemo(() => {
    const displayNum = width < TabletBreakpoint ? 4 : nearbyDestinations.length
    return nearbyDestinations.slice(0, displayNum)
  }, [nearbyDestinations, width])

  return nearbyDestinations.length ? (
    <section className={`${style.destinationsSection}`} id="NearbyDestinations">
      <div className={`${style.content}`}>
        <AutoPageSectionHeader
          labelText={`USE YOUR VACATION TIME`}
          subtitleText={`Check out these other destinations worthy of your PTO.`}
          titleText={`Nearby Places to Visit`}
        />
        <div className={style.facetsContainer}>
          {displayDestinations.map((destination, i) => (
            <Destination
              destination={destination}
              displayNum={displayDestinations.length}
              i={i}
              key={destination.objectID}
            />
          ))}
        </div>
      </div>
    </section>
  ) : null
}

export default NearbyDestinations
