import React, { useMemo } from 'react'

import useAugmentedRouter from 'hooks/useAugmentedRouter'

import style from './AutoPageFaq.module.scss'
import useFAQLinks from './useFAQLinks'

import AutoPageSectionHeader from '../SectionHeader/AutoPageSectionHeader'

import { FaqStructuredData } from 'utils/StructuredData'
import { preserveQuery, applyPreservedQuery } from 'utils/Strings'

import type { CountObject } from 'types/locationLanding'

// Sub Component
type FaqProps = {
  faq: { question: string; answer: string }
  i: number
}

const Faq: React.FC<FaqProps> = ({ faq, i }) => {
  return (
    <div className={style.faqItem}>
      <h5>{`#${i}`}</h5>
      <h3>{faq.question}</h3>
      <div className={`${style.answerWrapper}`}>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: faq.answer,
            }}
          />
        </div>
      </div>
    </div>
  )
}

type AutoPageFaqProps = {
  cityName: string
  listingsCount: number
  faqAmenitiesCounts: CountObject
  cityAddress?: string
}

const AutoPageFaq: React.FC<AutoPageFaqProps> = ({
  cityName,
  listingsCount,
  faqAmenitiesCounts,
  cityAddress,
}) => {
  const router = useAugmentedRouter()
  const preservedQuery = useMemo(
    () => preserveQuery(router.query),
    [router.query],
  )
  const [
    searchByCityUrl,
    searchByPetFriendlyUrl,
    searchByPoolUrl,
    searchByHubTub,
    searchByChildrenFriendlyUrl,
    searchByViewUrl,
    searchByParkingUrl,
    searchByWifiUrl,
  ] = useFAQLinks(cityAddress, preservedQuery)

  const staticLinks: Record<string, string> = useMemo(() => {
    const links = {
      restEasyPromise: 'https://evolve.com/rest-easy-promise',
      petFriendly: 'https://evolve.com/vacation-rentals/pet-friendly',
      topPetFriendly:
        'https://evolve.com/blog/travel-tips/top-pet-friendly-destinations-in-the-u-s',
      policyOnInclusion:
        'https://help.evolve.com/s/article/What-is-Evolve-s-policy-on-inclusion-and-community-behavior',
      luxuryVacations:
        'https://evolve.com/blog/travel-tips/luxury-vacations-in-the-us',
      familyFriendly:
        'https://evolve.com/blog/travel-tips/traveling-with-kids-5-family-friendly-amenities',
      contact: 'https://evolve.com/contact',
      forChildren:
        'https://evolve.com/vacation-rentals/search?amenities.Accessibility=Suitable-for-Children',
      travelTips: 'https://evolve.com/blog/travel-tips',
    }
    return Object.keys(links).reduce((staticLinks, linkKey) => {
      staticLinks[linkKey] = applyPreservedQuery(links[linkKey], preservedQuery)
      return staticLinks
    }, {})
  }, [preservedQuery])

  const faqs = [
    {
      question: `How many vacation rentals are in ${cityName}?`,
      answer: `<span>Evolve has <a href=\"${searchByCityUrl}\">${listingsCount} vacation rentals</a> around ${cityName} to explore. Whether you need a perfect place for the whole family to gather, or cozy retreats for just a few, we're confident you'll <a target=\"_blank\" href=\"${staticLinks.restEasyPromise}\">rest easy</a> in one of our vacation homes.<span>`,
    },
    {
      question: `Are there pet-friendly vacation rentals in ${cityName}?`,
      answer: `<span>${
        faqAmenitiesCounts.pets && faqAmenitiesCounts.pets >= 2
          ? `Yes! Evolve has <a href=\"${searchByPetFriendlyUrl}\">${faqAmenitiesCounts.pets} pet-friendly vacation rentals</a> around ${cityName} so you can plan amazing getaways <a target=\"_blank\" href=\"${staticLinks.petFriendly}\">with your four-legged friends.</a> You might find homes with fenced-in yards for a good game of fetch, or rentals with dog beds and toys to create the perfect home base for <a target=\"_blank\" href=\"${staticLinks.topPetFriendly}\">pup-approved adventures.</a>`
          : `Right now, all of our vacation rentals around ${cityName} are for humans only. We do, however, <a target=\"_blank\" href=\"${staticLinks.policyOnInclusion}\">accommodate</a> — and require our owners to accommodate — guests who need a service animal. We do not decline or charge an extra fee to guests who require a service animal to assist with their disability.`
      }</span>`,
    },
    {
      question: `Are there ${cityName} vacation rentals with a pool?`,
      answer: `<span>${
        faqAmenitiesCounts.pool && faqAmenitiesCounts.pool >= 2
          ? `You bet! Evolve has <a href=\"${searchByPoolUrl}\">${faqAmenitiesCounts.pool} vacation rentals</a> around ${cityName} with a private pool or access to a community pool. It's easy to make a splash with friends or family on the getaway of your dreams in these homes.`
          : `Evolve doesn't currently have any vacation rentals around ${cityName} with a private pool or community pool access. We welcome new vacation homes every day, though, so check back soon for updates!`
      }</span>`,
    },
    {
      question: `Are there ${cityName} vacation rentals with a hot tub?`,
      answer: `<span>${
        faqAmenitiesCounts.hotTub && faqAmenitiesCounts.hotTub >= 2
          ? `Indulging in a soothing soak after a day of exploring ${cityName} is easy — Evolve has <a href=\"${searchByHubTub}\">${faqAmenitiesCounts.hotTub} vacation rentals</a> with a hot tub to give your getaway an added <a target=\"_blank\" target=\"_blank\" href=\"${staticLinks.luxuryVacations}\">touch of luxury.</a>`
          : `Evolve doesn't have any vacation rentals with a hot tub around ${cityName} at this time. We welcome new vacation homes every day, though, so check back soon for updates! `
      }</span>`,
    },
    {
      question: `Are there kid-friendly vacation rentals in ${cityName}?`,
      answer: `<span>${
        faqAmenitiesCounts.children && faqAmenitiesCounts.children >= 2
          ? `${cityName} is a great place to bring the whole family, and Evolve offers <a href=\"${searchByChildrenFriendlyUrl}\">${faqAmenitiesCounts.children} kid-friendly vacation rentals</a> in the area. Many have <a target=\"_blank\" href=\"${staticLinks.familyFriendly}\">family-friendly amenities</a> like game rooms, Pack N 'Plays, or fenced-in pools to make slipping into vacation mode easy for every age group.`
          : `Right now, all of the vacation rentals around ${cityName} are well-suited for full-grown adults — but that doesn't mean you and your loved ones can't have the trip of your dreams. <a target=\"_blank\" href=\"${staticLinks.contact}\">Chat with our team</a> to get personalized help with finding a vacation home that fits all of your needs, or check out our <a target=\"_blank\" href=\"${staticLinks.forChildren}\">family-friendly vacation rentals</a> across the country for more <a target=\"_blank\" href=\"${staticLinks.travelTips}\">vacation ideas.</a>`
      }</span>`,
    },
    {
      question: `Which vacation rentals in ${cityName} have good views?`,
      answer: `<span>${
        faqAmenitiesCounts.views && faqAmenitiesCounts.views >= 2
          ? `It's not just about what's going on inside your vacation home; it's also about the scenery. Evolve has <a href=\"${searchByViewUrl}\">${faqAmenitiesCounts.views} vacation rentals</a> around ${cityName} with stunning views of the area, prime for relaxation and a good old-fashioned photo opp.`
          : `Evolve's vacation rentals around ${cityName} are full of popular amenities that make your in-home experience unforgettable, but they may not have sweeping views of the area at this time. That said, we welcome new vacation rentals daily, so check back soon for homes with views that are worthy of a good old-fashioned photo opp.`
      }</span>`,
    },
    {
      question: `Are there any vacation rentals in ${cityName} with parking?`,
      answer: `<span>${
        faqAmenitiesCounts.parking && faqAmenitiesCounts.parking >= 2
          ? `Yes! Evolve offers <a href=\"${searchByParkingUrl}\">${faqAmenitiesCounts.parking} vacation rentals</a> around ${cityName} with driveways, garages, or dedicated parking spaces to make arrivals and departures from your vacation home base as convenient as possible.`
          : `Evolve doesn't have any vacation rentals around ${cityName} with dedicated parking available at this time, though you may be able to find street parking nearby. We also welcome new vacation homes every day, so check back soon for homes offering private parking with a reservation.`
      }</span>`,
    },
    {
      question: `Do any vacation rentals in ${cityName} have WiFi?`,
      answer: `<span>${
        faqAmenitiesCounts.wifi && faqAmenitiesCounts.wifi >= 2
          ? `Evolve has <a href=\"${searchByWifiUrl}\">${faqAmenitiesCounts.wifi} vacation rentals</a> with WiFi around ${cityName}, helping you stay plugged in while you get away. Whether you're hoping to stream a favorite show or knock out some work, a bad connection should never stand in the way.`
          : `Evolve's vacation rentals around ${cityName} don't currently offer WiFi, making it easy for you to totally unplug and get away. Plus, we're always just a phone call away if you're ever in need of assistance.`
      }</span>`,
    },
  ]

  return (
    <section id="FAQ">
      <AutoPageSectionHeader
        subtitleText={`Get answers to some of the most frequently asked questions about vacation rentals in ${cityName}.`}
        titleText={`Questions About Renting in ${cityName}?`}
      />
      {/* Manually generate 2 column layout above mobile widths */}
      <div className={`${style.faqGrid}`}>
        <div className={style.faqCol}>
          {faqs.slice(0, 4).map((faq, i) => (
            <Faq faq={faq} i={i + 1} key={faq.question} />
          ))}
        </div>
        <div className={style.faqCol}>
          {faqs.slice(4, faqs.length).map((faq, i) => (
            <Faq faq={faq} i={i + 5} key={faq.question} />
          ))}
        </div>
      </div>
      {faqs.length && <FaqStructuredData faqData={faqs} />}
    </section>
  )
}

export default AutoPageFaq
