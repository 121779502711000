import { stringify } from 'qs'
import isString from 'lodash/isString'
import omit from 'lodash/omit'
import deepmerge from 'deepmerge'

import { allQualityTypesByKey } from './QuerySearchBuilder.constants'
import type { Qualities, QueryParams } from './QuerySearchBuilder.types'

import { urlPrettySpaces, normalizeSearchQuery } from 'utils/Strings'
import { deepOmitBy } from 'utils/objectUtils'

class QuerySearchBuilder {
  private initialParams?: QueryParams

  constructor(params?: QueryParams) {
    this.initialParams = params
  }

  private mapQualitiesWithKeys = (qualities: Partial<Qualities>) => {
    const { accessibility, amenities, views } = qualities
    const quelitiesAndKeys = [
      { quality: accessibility, key: 'amenities.Accessibility' },
      { quality: amenities, key: 'amenities.Amenities' },
      { quality: views, key: 'amenities.View' },
    ]

    return quelitiesAndKeys.reduce((acc, { quality, key }) => {
      return {
        ...acc,
        [key]: [...(quality || [])].map((value) => this.formatSpaces(value)),
      }
    }, {})
  }

  private getQualityAllTypes = (
    qualities: Partial<Qualities>,
  ): Partial<Qualities> => {
    return Object.keys(qualities).reduce((acc, qualityKey) => {
      const allQualityTypes = allQualityTypesByKey[qualityKey]
      return {
        ...acc,
        [qualityKey]: allQualityTypes,
      }
    }, {})
  }

  private getQualities = (
    qualities: Partial<Qualities>,
  ): Partial<Qualities> => {
    const sanitizedQualities = Object.fromEntries(
      Object.entries(qualities).filter(([key, value]) => Boolean(value)),
    )

    const qualitiesWithSomeTypes = Object.entries(sanitizedQualities).reduce(
      (acc, [key, value]) => {
        if (!isString(value)) {
          acc[key] = value
        }
        return acc
      },
      {},
    )

    const omittedKeys = Object.keys(qualitiesWithSomeTypes)
    const qualitiesWithAllTypes = this.getQualityAllTypes({
      ...omit(sanitizedQualities, omittedKeys),
    })

    return {
      ...qualitiesWithSomeTypes,
      ...qualitiesWithAllTypes,
    }
  }

  private parsedQualities = (qualitiesParams: Partial<Qualities> = {}) => {
    const qualities = this.getQualities(qualitiesParams)

    return this.mapQualitiesWithKeys(qualities)
  }

  private formatSpaces = (value?: string): string => {
    return urlPrettySpaces(normalizeSearchQuery(value || ''))
  }

  public build = (params?: QueryParams): string => {
    const queryParams = deepmerge(this.initialParams || {}, params || {})
    queryParams.query = this.formatSpaces(queryParams.query)
    const { qualities: qualitiesParams, ...restParams } = queryParams
    const qualities = this.parsedQualities(qualitiesParams)
    const sanitazedParams = deepOmitBy({ ...restParams, ...qualities }, Boolean)

    return stringify(sanitazedParams, { arrayFormat: 'repeat' })
  }
}

export default QuerySearchBuilder
