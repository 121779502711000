import React from 'react'
import Image from 'next/image'

import { useSelect } from 'store/index'

import CustomLink from 'components/Link/CustomLink'

import style from './TopFacets.module.scss'

import { DesktopBreakpoint } from 'config/Breakpoints'

import getWordpressCloudinaryId from 'utils/strings/getWordpressCloudinaryId'
import cloudinaryLoader from 'utils/imageLoaders/cloudinaryLoader'

import Check from 'assets/icons/icon-checkmark.svg'

import type { CloudinaryImageData } from 'types/locationLanding'

export type FacetProps = {
  facetData: {
    searchUrl: string
    image: CloudinaryImageData
    displayText: string
  }
}

const Facet: React.FC<FacetProps> = ({ facetData }) => {
  // Redux Selectors
  const width = useSelect((state) => state.uiState.width)

  return (
    <div className={style.facet}>
      <CustomLink
        className={style.link}
        href={`/vacation-rentals/search${facetData.searchUrl}`}
        prefetch={false}
      >
        <div className={`${style.image}`}>
          {facetData.image?.url && (
            <Image
              alt={facetData.image.context?.alt ?? ''}
              fill
              loader={cloudinaryLoader}
              loading="lazy"
              priority={false}
              quality={width < DesktopBreakpoint ? 55 : 65}
              sizes="(min-width: 900px) 320px, (min-width: 600px) 250px, 150px"
              src={getWordpressCloudinaryId(facetData.image.url)!}
              style={{
                objectFit: 'cover',
              }}
              title={
                facetData.image.context?.caption ??
                facetData.image.context?.alt ??
                ''
              }
            />
          )}
        </div>

        <div className={'description'}>
          <div>
            <label className={style.propertyLabel}>
              VERIFIED HOMES <Check height={12} width={12} />
            </label>
          </div>
          <div className={style.displayText}>{facetData.displayText}</div>
        </div>
      </CustomLink>
    </div>
  )
}

export default Facet
