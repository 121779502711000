import { useEffect, useState } from 'react'

import QuerySearchBuilder from 'utils/search/QuerySearchBuilder/QuerySearchBuilder'
import type { QueryParams } from 'utils/search/QuerySearchBuilder/QuerySearchBuilder.types'

const useQuerySearchBuilder = (
  initialParams?: QueryParams,
): QuerySearchBuilder => {
  const [querySearchBuilder, setQueryBuilder] = useState(
    new QuerySearchBuilder(),
  )

  useEffect(() => {
    setQueryBuilder(new QuerySearchBuilder(initialParams))
  }, [initialParams])

  return querySearchBuilder
}

export default useQuerySearchBuilder
