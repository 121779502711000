import type { Dictionary, ValueKeyIteratee } from 'lodash'
import pickBy from 'lodash/pickBy'

const isObject = (obj: any): boolean => {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

export const deepOmitBy = <T>(
  input: Dictionary<T> | null | undefined,
  omitFn?: ValueKeyIteratee<T>,
): Dictionary<T> => {
  const omitDeepOnOwnProps = (obj) => {
    if (typeof input === 'undefined') {
      return input
    }

    const isNotObject = !isObject(obj)
    if (isNotObject) {
      return obj
    }

    const o = Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = isNotObject
        ? value
        : deepOmitBy(value as Dictionary<any>, omitFn)

      return acc
    }, {})

    return pickBy(o, omitFn)
  }

  return omitDeepOnOwnProps(input)
}
