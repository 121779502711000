import React from 'react'

import {
  sectionLabelCentered,
  centeredTitle,
  centeredTitleSm,
  whiteText,
} from './Section-Label-Centered.module.scss'

const SectionLabelCentered = (props) => {
  const { label, title, subtitle, smallTitle, white, top } = props

  return (
    <div className={sectionLabelCentered}>
      {label && (
        <span className={`section-label blue ${white ? 'white' : ''}`}>
          {label}
        </span>
      )}
      {top ? (
        <h1
          className={`section-title ${centeredTitle} ${white ? whiteText : ''}`}
        >
          {title}
        </h1>
      ) : (
        <h2
          className={`section-title-sm ${
            smallTitle ? centeredTitleSm : centeredTitle
          } ${white ? whiteText : ''}`}
        >
          {title}
        </h2>
      )}
      <span className={`section-subtitle-sm ${white ? whiteText : ''}`}>
        {subtitle}
      </span>
    </div>
  )
}

export default SectionLabelCentered
