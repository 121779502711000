import React from 'react'

import { Breadcrumb } from 'components/Breadcrumb'

import styles from './Breadcrumb.module.scss'

import type { BreadcrumbListItem } from 'types/externalData'

type BreadcrumbUIProps = {
  breadcrumbData: BreadcrumbListItem[]
  onBreadcrumbItemClicked?: (
    e: React.MouseEvent<HTMLAnchorElement>,
    bItem: BreadcrumbListItem,
    dataPath: string,
  ) => void
}

const BreadcrumbUI: React.FC<BreadcrumbUIProps> = ({
  breadcrumbData,
  onBreadcrumbItemClicked,
}) => {
  return (
    <section className={styles.breadcrumbContainer}>
      <Breadcrumb
        items={breadcrumbData}
        onBreadcrumbItemClicked={onBreadcrumbItemClicked}
      />
    </section>
  )
}

export default BreadcrumbUI
