import React, { useState } from 'react'

import SeeMoreButton from 'components/Result-Details/SeeMoreButton'

import style from './Overview.module.scss'

import { stripWrappingParagraph } from 'utils/Strings'

type OverviewProps = {
  overviewData: {
    label: string
    title: string
    body: string
    image: { url: string; alt: string }
    read_more: string
  }
}

const Overview: React.FC<OverviewProps> = ({ overviewData }) => {
  const { label, title, body, image, read_more } = overviewData
  const [open, setOpen] = useState(false)

  return (
    <section className={`${style.section}`} id="overview">
      <div className={`${style.content} content`}>
        <div className={`${style.overview}`}>
          <div className={`${style.overviewHeader}`}>
            <span className={'section-label blue'}>{label}</span>
            <h2 className={`section-title`}>{title}</h2>
            <span
              className={`section-subtitle-sm`}
              dangerouslySetInnerHTML={{ __html: stripWrappingParagraph(body) }}
            />
            {read_more && (
              <span className={style.buttonWrapper}>
                <SeeMoreButton
                  customText={{
                    isOpenText: 'Read More',
                    isClosedText: 'Read More',
                  }}
                  id="destination_overview_see_more_btn"
                  open={open}
                  setOpen={setOpen}
                />
              </span>
            )}
          </div>
          {open && read_more && (
            <div
              className={`section-subtitle-sm ${style.readMore}`}
              dangerouslySetInnerHTML={{
                __html: stripWrappingParagraph(read_more),
              }}
            />
          )}
          {image && (
            <img
              alt={image.alt ? image.alt : title}
              className={style.overviewImage}
              src={image.url}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default Overview
