import React, { useEffect } from 'react'

import useAugmentedRouter from 'hooks/useAugmentedRouter'
import { useLLPAnalyticsPage } from 'hooks/useLLPAnalyticsPage'

import ManualPageHero from 'components/LocationLanding/ManualPage/Hero/ManualPageHero'
import RecommendedProperties from 'components/RecommendedProperties/RecommendedProperties'
import Overview from 'components/LocationLanding/ManualPage/Overview/Overview'
import DescriptiveCards from 'components/LocationLanding/ManualPage/DescriptiveCards/DescriptiveCards'
import ManualPageFaq from 'components/LocationLanding/ManualPage/Faq/ManualPageFaq'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import EvolveDifference from 'components/EvolveDifference/EvolveDifference'
import RecommendedDestinationsCarousel from 'components/Carousels/RecommendedDestinationsCarousel'
import VacationIdeas from 'components/LocationLanding/ManualPage/VacationIdeas/VacationIdeas'
import ManualPageMetaTags from 'components/LocationLanding/ManualPage/MetaTags/ManualPageMetaTags'
import Gtm from 'components/scripts/Gtm'

import style from './ManualLocationLandingPage.module.scss'

import { buildYoastTags } from 'utils/Seo'
import {
  WebpageStructuredData,
  LocationPagePlaceStructuredData,
  DateModifiedStructuredData,
} from 'utils/StructuredData'

export type ManualLocationLandingPageProps = {
  footerData: any
  pageData: any
  pageTitle: string
  evolveDifference: any
  metaDesc: string
  seoData: any
  filteredTags: any
}

const ManualLocationLandingPage: React.FC<ManualLocationLandingPageProps> = ({
  footerData,
  pageData,
  pageTitle,
  evolveDifference,
  metaDesc,
  seoData,
  filteredTags,
}) => {
  const router = useAugmentedRouter()

  // Can't scroll to anchor until document is ready
  useEffect(() => {
    if (!router.isFallback) {
      const anchor = document.getElementById(location.hash.substring(1))
      const scrollOffset =
        location.hash.substring(1) === 'things-to-do' ||
        location.hash.substring(1) === 'faq'
          ? 50
          : 0

      if (anchor) {
        window.scrollTo({
          top:
            anchor.getBoundingClientRect().top +
            window.pageYOffset -
            scrollOffset,
          behavior: 'smooth',
        })
      }
    }
  }, [router.isFallback])

  useLLPAnalyticsPage()

  return (
    <>
      <ManualPageMetaTags
        overviewImage={pageData.acf?.overview.image.url}
        pageData={pageData}
        pageTitle={pageTitle}
        seoData={seoData}
        yoastData={pageData.yoast_meta}
      />

      <Gtm pageType="" />
      <Header transparent />

      {/* Always show hero and overview, optionally show other sections */}
      <ManualPageHero heroGroup={pageData.acf.hero_group} />
      <div
        className={`${style.main} ${
          pageData.acf.recommended_properties.hide_this_section
            ? style.topPadSection
            : ''
        }`}
      >
        <Overview overviewData={pageData.acf.overview} />
        {!pageData.acf.recommended_properties.hide_this_section && (
          <RecommendedProperties
            recommendedData={pageData.acf.recommended_properties}
          />
        )}
        {!pageData.acf.descriptive_cards.hide_this_section && (
          <DescriptiveCards cardData={pageData.acf.descriptive_cards} />
        )}
        {!pageData.acf.faq.hide_this_section && (
          <ManualPageFaq faqData={pageData.acf.faq} />
        )}
        {!pageData.acf.hide_evolve_difference_section && (
          <EvolveDifference evolveDifference={evolveDifference} small={false} />
        )}
      </div>
      {!pageData.acf.recommended_destinations.hide_this_section && (
        <RecommendedDestinationsCarousel
          customDestinations={pageData.acf.recommended_destinations}
          id="destinations"
        />
      )}
      {!pageData.acf.more_vacation_ideas.hide_this_section && (
        <VacationIdeas data={pageData.acf.more_vacation_ideas} />
      )}
      <WebpageStructuredData
        canonicalUrl=""
        description={metaDesc}
        name={pageTitle}
        yoastData={pageData.yoast_meta}
      />
      {pageData.modified_gmt && (
        <DateModifiedStructuredData date={pageData.modified_gmt} />
      )}
      {pageData.acf.hero_group.default_search.address && (
        <LocationPagePlaceStructuredData
          location={pageData.acf.hero_group.default_search.address}
        />
      )}
      {filteredTags.length && buildYoastTags(filteredTags)}

      <Footer footerData={footerData} />
    </>
  )
}

export default ManualLocationLandingPage
